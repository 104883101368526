import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      display: 'flex',
      border: `1px solid ${theme.palette.grey[500]}`,
      padding: 18,
      borderRadius: theme.shape.borderRadius,
      position: 'relative',
      cursor: 'text',
      '& p': {
        fontFamily: theme.typography.fontFamily,
      },
      '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& [type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
    input: {
      width: '100%',
      outline: 'none',
      border: 'none',
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSize: 18,
      height: 28,
      flex: 1,
    },
    inputError: {
      border: `2px solid ${theme.palette.error.main}`,
    },
    errorIconContainer: {
      flex: 1,
      display: 'flex',
      // Circle X icon
      '&::after': {
        content: '"f"',
        fontSize: 19,
        fontFamily: 'alberts',
        color: theme.palette.error.main,
      },
    },
    errorHelperText: {
      position: 'absolute',
      top: 6,
      margin: 0,
      color: theme.palette.error.main,
      fontSize: 12,
    },
    innerContainer: {
      width: '100%',
    },
  })
);
