import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { formatUserInputDate } from '../../helpers';
import { ITimeGap } from '../../types';
import useStyles from './styles';

interface ICalendarItem {
  timeGap: ITimeGap;
  chosenStartDate?: string;
  chosenStartTime?: string;
  handleClick: (timeGap: ITimeGap) => void;
}

export const CalendarItem = ({
  chosenStartDate,
  chosenStartTime,
  timeGap,
  handleClick,
}: ICalendarItem) => {
  const classes = useStyles();
  return (
    <Grid item xs={4} sm={3} md={2} lg="auto">
      <Box
        className={`${classes.item} ${
          chosenStartDate === formatUserInputDate(timeGap.startDate) &&
          chosenStartTime === timeGap.startTime
            ? classes.choosenItem
            : classes.notChoosenItem
        }`}
        onClick={() => handleClick(timeGap)}
      >
        <Typography>{timeGap.startTime}</Typography>
      </Box>
    </Grid>
  );
};
