import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    item: {
      padding: '10px 25px',
      borderRadius: 30,
      cursor: 'pointer',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 0px',
      },
    },
    notChoosenItem: {
      border: `1px ${theme.palette.grey[400]} solid`,
    },
    choosenItem: {
      border: `1px ${theme.palette.primary.main} solid`,
      backgroundColor: theme.palette.primary.light,
    },
  })
);
