import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    buttonBox: {
      margin: '0 auto 1.5rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',

      padding: 0,
    },
    checkbox: {
      color: theme.palette.grey[500],
    },
    error: {
      color: theme.palette.warning.dark,
      fontWeight: 700,
      fontSize: 13,
    },
    submitButton: {
      marginTop: '1.625rem',
    },
    link: {
      color: theme.palette.secondary.main,
      textDecorationColor: theme.palette.secondary.main,
      marginLeft: 5,
    },
  })
);
