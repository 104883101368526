import React, { useRef } from 'react';

import useStyles from './styles';

interface IInput {
  placeholder: string;
  error: boolean;
  errorHelperText: string;
  value: string | number;
  type?: React.HTMLInputTypeAttribute;
  onChange: (value: string) => void;
}

export const Input = ({
  placeholder,
  error,
  errorHelperText,
  type,
  value,
  onChange,
}: IInput) => {
  const classes = useStyles();
  const input = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (input.current !== null) {
      input.current.focus();
    }
  };

  return (
    <div
      className={`${classes.inputContainer} ${error && classes.inputError}`}
      onClick={handleClick}
    >
      {error && <p className={classes.errorHelperText}>{errorHelperText}</p>}
      <div
        className={error ? classes.errorIconContainer : classes.innerContainer}
      >
        <input
          placeholder={placeholder}
          value={value}
          type={type ?? 'text'}
          className={classes.input}
          onWheel={() => {
            input.current?.blur();
          }}
          ref={input}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
