import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    check: {
      display: 'flex',
      // Checkmark icon
      '&::before': {
        content: '"C"',
        fontFamily: 'alberts',
        fontSize: 20,
        [theme.breakpoints.only('xs')]: {
          fontSize: 18,
        },
      },
    },
  })
);
