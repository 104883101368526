export interface IHomeCleaningForm
  extends IContactInformation,
    IMeetingInformation {
  meetingType: MeetingTypes;
}

export type ValueOf<T> = T[keyof T];

export interface IFormSubmissionState {
  data: boolean;
  loading: boolean;
  error: boolean;
}

export interface IContactInformation {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  sqmSize: string;
}

export interface IMeetingInformation {
  meetingEmployeeId: string;
  startDate: string;
  startTime: string;
  endTime: string;
}
export interface ITimewaveInformationState {
  employeeIdsConfig: EmployeeIdsConfig;
  serviceIdsConfig: ServiceIdsConfig;
}
type EmployeeIdsConfig = {
  responsibleEmployeeId: string;
  physicalMeetingEmployeeIds: Cities;
  digitalMeetingEmployeeIds: Cities;
};
type Cities = {
  gothenburg: string;
  stockholm: string;
  uppsala?: string;
};
type ServiceIdsConfig = {
  physicalMeetingServiceIds: Cities;
  digitalMeetingServiceIds: Cities;
};
export enum MeetingTypes {
  VIDEO = 'VIDEO',
  PHYSICAL = 'PHYSICAL',
}
