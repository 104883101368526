import React, { useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';

import { CustomStepper } from '../CustomStepper';
import { ButtonGroup } from '../ButtonGroup';
import stringData from '../../assets/data/formStringsData.json';
import {
  ContactInformation,
  ResidenceInformation,
  MeetingInformation,
} from '../steps';
import { useFormContext, useUsageContext } from '../../hooks';
import { validatePostalCodeRange } from '../../helpers';
import useStyles from './styles';
import {
  GAAction,
  GACategory,
  userSubmittedForm,
} from '../../contexts/usage/helpers';

declare var fbq: Function;

export const Form = () => {
  const classes = useStyles();
  const {
    validateFields,
    setFormValues,
    submitForm,
    formSubmissionState,
    homeCleaningFormValues,
    timewaveInformationState,
    sendConfirmationEmails,
  } = useFormContext();
  const buttonRef = useRef<HTMLDivElement>(null);
  const formString = stringData.form;
  const { height, ref } = useResizeDetector();
  const { userStartedForm, userVisitedPage } = useUsageContext();
  const [activeStepId, setActiveStepId] = useState(0);
  const [invalidPostalCodeError, setInvalidPostalCodeError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  useEffect(() => {
    userStartedForm(GACategory.HOME_FORM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (activeStepId) {
      case 0:
        userVisitedPage(GACategory.HOME_FORM, GAAction.FORM_PAGE_1);
        break;
      case 1:
        userVisitedPage(GACategory.HOME_FORM, GAAction.FORM_PAGE_2);
        break;
      case 2:
        userVisitedPage(GACategory.HOME_FORM, GAAction.FORM_PAGE_3);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepId]);

  const scrollToTop = () => {
    if (window.parent) {
      if (window.top) {
        window.top.postMessage(
          { task: 'scroll_top' },
          formString.parentUrl // switch to local development url in development
        );
      } else {
        window.parent.postMessage(
          { task: 'scroll_top' },
          formString.parentUrl // switch to local development url in development
        );
      }
    }
  };

  const handleNext = () => {
    if (validateFields(activeStepId)) {
      setDisplayErrorMessage(false);
      if (!validatePostalCodeRange(homeCleaningFormValues.postalCode)) {
        return setInvalidPostalCodeError(true);
      }

      setInvalidPostalCodeError(false);
      setActiveStepId((prevActiveStepId) => prevActiveStepId + 1);
      scrollToTop();
    } else {
      setDisplayErrorMessage(true);
    }
  };

  const resetMeetingFormValues = () => {
    setFormValues('meetingEmployeeId', '');
    setFormValues('startDate', '');
    setFormValues('startTime', '');
    setFormValues('endTime', '');
  };

  const handleBack = () => {
    // reset selected meeting when going back to ContactInformation step
    if (activeStepId === 1) {
      resetMeetingFormValues();
    }
    setActiveStepId((prevActiveStepId) => prevActiveStepId - 1);
    scrollToTop();
    setDisplayErrorMessage(false);
  };

  const handleDisplayErrorMessage = (boolean: boolean) => {
    setDisplayErrorMessage(boolean);
  };

  const handleScrollToButton = () => {
    if (window.parent) {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        if (window.top) {
          window.top.postMessage(
            {
              task: 'scroll_to_button',
              offset: rect.top,
            },
            formString.parentUrl
          );
        } else {
          window.parent.postMessage(
            {
              task: 'scroll_to_button',
              offset: rect.top,
            },
            formString.parentUrl
          );
        }
      }
    }
  };

  useEffect(() => {
    if (formSubmissionState.data && window.top) {
      setIsSubmitted(true);
      sendConfirmationEmails();
      userSubmittedForm(GACategory.HOME_FORM);
      fbq('track', 'CompleteRegistration');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmissionState]);

  const steps = [
    {
      label: 'Om din bostad',
      component: <ResidenceInformation />,
    },
    {
      label: 'Mötestid',
      component: (
        <MeetingInformation handleScrollToButton={handleScrollToButton} />
      ),
    },
    {
      label: 'Kontaktuppgifter',
      component: <ContactInformation />,
    },
  ];

  const setIframeHeight = () => {
    // sends height to parent iframe
    if (window.parent) {
      if (window.top) {
        window.top.postMessage(
          { task: 'set_iframe_height', height: height },
          formString.parentUrl // switch to local development url in development
          // This is the url for the test site 'https://www.bettysstad.se/test-offert-pa-hemstadning/'
        );
      } else {
        window.parent.postMessage(
          { task: 'set_iframe_height', height: height },
          formString.parentUrl // switch to local development url in development
          // This is the url for the test site 'https://www.bettysstad.se/test-offert-pa-hemstadning/'
        );
      }


    }
  };

  useEffect(() => {
    setIframeHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, timewaveInformationState]);

  if (formSubmissionState.loading) {
    return (
      <Box className={classes.root}>
        <Box ref={ref}>
          <Box textAlign={'center'} marginBottom={'55px'}>
            <Typography variant="h1">{formString.heading}</Typography>
          </Box>
          <CustomStepper activeStep={activeStepId} steps={steps} />
          <Box className={classes.loadingContainer} sx={{ height: 100 }}>
            <CircularProgress size={65} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box ref={ref}>
        {isSubmitted ? (
          <Box textAlign={'center'} padding={'15% 0px'}>
            <Typography variant="h1" marginBottom={'15px'}>
              {stringData.thankYou.heading}
            </Typography>
            <Typography variant="body1">{stringData.thankYou.text}</Typography>
            <Typography variant="body1">
              {stringData.thankYou.subHeading}
            </Typography>
          </Box>
        ) : (
          <>
            <Box textAlign={'center'} marginBottom={'55px'}>
              <Typography variant="h1">{formString.heading}</Typography>
            </Box>
            <CustomStepper activeStep={activeStepId} steps={steps} />
            {steps.map(
              (step, index) =>
                index === activeStepId && (
                  <Box key={index}>{step.component}</Box>
                )
            )}
            {formSubmissionState.error && (
              <>
                <Typography className={classes.errorText}>
                  {formString.formSubmissionErrorText}
                </Typography>
                <Box className={classes.linkContainer}>
                  <Typography className={classes.link}>
                    {formString.bookThroughOldForm}
                  </Typography>
                  <Link
                    target="_blank"
                    href={formString.oldFormUrl}
                    className={classes.link}
                  >
                    {formString.here}
                  </Link>
                </Box>
              </>
            )}
            {invalidPostalCodeError && (
              <Typography className={classes.errorText}>
                {formString.invalidPostalCodeErrorText}
              </Typography>
            )}
            <Box ref={buttonRef}></Box>
            <ButtonGroup
              activeStep={activeStepId}
              stepsLength={steps.length}
              displayErrorMessage={displayErrorMessage}
              handleDisplayErrorMessage={(boolean) =>
                handleDisplayErrorMessage(boolean)
              }
              submitForm={submitForm}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
