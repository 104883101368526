import React from 'react';
import { Grid } from '@mui/material';

import { FormSection } from '../../FormSection';
import { Input } from '../../Input';
import stringData from '../../../assets/data/formStringsData.json';
import { useFormContext } from '../../../hooks';

export const ResidenceInformation = () => {
  const { setFormValues, fieldErrors, homeCleaningFormValues } =
    useFormContext();
  const residenceInformationStrings = stringData.steps.residenceInformation;

  return (
    <FormSection title={residenceInformationStrings.residenceInformation.title}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            placeholder={
              residenceInformationStrings.residenceInformation.fields.sqmSize
                .placeholder
            }
            error={fieldErrors.sqmSize}
            errorHelperText={
              residenceInformationStrings.residenceInformation.fields.sqmSize
                .errorHelperText
            }
            key={'sqmSize'}
            value={homeCleaningFormValues['sqmSize']}
            onChange={(value) => {
              setFormValues('sqmSize', value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            placeholder={
              residenceInformationStrings.residenceInformation.fields.postalCode
                .placeholder
            }
            error={fieldErrors.postalCode}
            errorHelperText={
              residenceInformationStrings.residenceInformation.fields.postalCode
                .errorHelperText
            }
            type="number"
            value={homeCleaningFormValues['postalCode']}
            onChange={(value) => setFormValues('postalCode', value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            placeholder={
              residenceInformationStrings.residenceInformation.fields.address
                .placeholder
            }
            error={fieldErrors.address}
            errorHelperText={
              residenceInformationStrings.residenceInformation.fields.address
                .errorHelperText
            }
            value={homeCleaningFormValues['address']}
            onChange={(value) => setFormValues('address', value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            placeholder={
              residenceInformationStrings.residenceInformation.fields.city
                .placeholder
            }
            error={fieldErrors.city}
            errorHelperText={
              residenceInformationStrings.residenceInformation.fields.city
                .errorHelperText
            }
            value={homeCleaningFormValues['city']}
            onChange={(value) => setFormValues('city', value)}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
