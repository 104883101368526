import { ReactNode } from 'react';

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

const FONT_PRIMARY = ['Source Sans Pro', 'sans-serif'].join(',');

export let theme = createTheme({
  typography: {
    fontFamily: FONT_PRIMARY,
    h1: {
      fontFamily: FONT_PRIMARY,
      fontSize: 46,
      fontWeight: 700,
      lineHeight: 1.1,
      color: '#1e2f56',
    },
    h2: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 750,
      fontSize: 30,
      color: '#1e2f56',
      lineHeight: 1.5,
    },
    body1: {
      lineHeight: 1.5,
      fontSize: 18,
      color: '#8a8a8a',
    },
    body2: {
      fontSize: 18,
      color: '#393939',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      default: '#e7f7f8',
      paper: '#fff',
    },
    grey: {
      100: '#393939',
      200: '#676767',
      300: '#8a8a8a',
      400: '#999',
      500: '#c1c1c1',
      600: '#f8f8f8',
    },
    text: {
      primary: '#000', // common black
      secondary: '#393939', // grey 200
    },
    primary: {
      main: '#34ac57',
      light: '#ebf7ee',
    },
    secondary: {
      main: '#1e2f56',
    },
    warning: {
      main: '#ee6a1e',
      dark: '#e02020',
    },
  },
  shape: {
    borderRadius: 4,
  },
  breakpoints: {
    values: {
      // extra-small
      xs: 0,
      // small
      sm: 600,
      // medium
      md: 760,
      // large
      lg: 1200,
      // extra-large
      xl: 1536,
    },
  },
});

theme = responsiveFontSizes(theme);

type ThemeConfigProps = {
  children: ReactNode;
};

export const ThemeConfig = ({ children }: ThemeConfigProps) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
