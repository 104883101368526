import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.grey[400],
      backgroundColor: 'transparent',
      border: 'none',
    },
    text: {
      display: 'flex',
      // Chevron-left icon
      '&::before': {
        content: '"z"',
        fontSize: 14,
        lineHeight: 2.1,
        marginRight: 5,
        fontFamily: 'alberts',
        color: theme.palette.grey[400],
      },
    },
  })
);
