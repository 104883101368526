import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '64px 91px',
      boxShadow: '0 0 8px rgb(0 0 0 / 14%)',
      borderRadius: theme.shape.borderRadius,
      marginBottom: 26,
      [theme.breakpoints.down('md')]: {
        padding: '3rem 2.6875rem',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '2rem 1rem 1rem',
      },
    },
    header: {
      textAlign: 'center',
      width: 'fit-content',
      margin: '0 auto 0 auto',
      marginBottom: 40,
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto 16px',
      },
    },
  })
);
