import React from 'react';
import { Box, StepIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import useStyles from './styles';

export const StepIconStyledComponent = styled(Box)<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[400],
  zIndex: 1,
  color: theme.palette.background.paper,
  fontSize: '1.5rem',
  fontWeight: 700,
  width: 37,
  height: 37,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.secondary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.secondary.main,
  }),
  [theme.breakpoints.only('xs')]: {
    width: 32,
    height: 32,
    fontSize: '1.2rem',
  },
}));

export const StepIcon = (props: StepIconProps) => {
  const classes = useStyles();
  const { active, completed, className } = props;

  const icons: { [index: string]: string } = {
    1: '1',
    2: '2',
    3: '3',
  };
  return (
    <StepIconStyledComponent
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? (
        <Box className={classes.check} />
      ) : (
        icons[String(props.icon)]
      )}
    </StepIconStyledComponent>
  );
};
