import React from 'react';
import { Typography } from '@mui/material';

import useStyles from './styles';

interface IPrimaryButton {
  text: string;
  onClick: () => void;
}

export const PrimaryButton = ({ text, onClick }: IPrimaryButton) => {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={onClick}>
      <Typography className={classes.text}>{text}</Typography>
    </button>
  );
};
