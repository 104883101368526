import React from 'react';
import { Box, Typography } from '@mui/material';

import useStyles from './styles';

interface ICheckbox {
  isChecked: boolean;
  text: string;
  onClick: () => void;
}

export const Checkbox = ({ isChecked, text, onClick }: ICheckbox) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.box} ${
        isChecked ? classes.checked : classes.notChecked
      }`}
      onClick={onClick}
    >
      <Typography
        variant="body1"
        className={isChecked ? classes.textChecked : classes.textNotChecked}
      >
        {text}
      </Typography>
    </Box>
  );
};
