import { gql } from '@apollo/client';

export const CREATE_QUOTE_HOME_CLEANING = gql`
  mutation createQuoteHomeCleaning(
    $userInput: UserInputHomeCleaning!
    $responsibleEmployeeId: String!
    $serviceIdsConfig: ServiceIdsConfig!
  ) {
    createQuoteHomeCleaning(
      userInput: $userInput
      responsibleEmployeeId: $responsibleEmployeeId
      serviceIdsConfig: $serviceIdsConfig
    )
  }
`;
