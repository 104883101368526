import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

import { StyledConnector, StepIcon, ICustomStepper } from './index';
import useStyles from './styles';

export const CustomStepper = ({ activeStep, steps }: ICustomStepper) => {
  const classes = useStyles();

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<StyledConnector />}
    >
      {steps.map((step, index) => {
        const stepProps: {
          completed?: boolean;
          active?: boolean;
          disabled?: boolean;
        } = {};
        return (
          <Step key={index} {...stepProps} className={classes.step}>
            <StepLabel
              className={classes.stepLabel}
              classes={{
                completed: classes.active,
                active: classes.active,
                disabled: classes.disabled,
                label: classes.stepLabel,
              }}
              StepIconComponent={StepIcon}
            >
              {step.label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
