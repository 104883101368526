import React from 'react';
import { Typography } from '@mui/material';

import useStyles from './styles';

interface ISecondaryButton {
  text: string;
  onClick: () => void;
}

export const SecondaryButton = ({ text, onClick }: ISecondaryButton) => {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={onClick}>
      <Typography className={classes.text}>{text}</Typography>
    </button>
  );
};
