import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 10px',
      [theme.breakpoints.only('xs')]: {
        margin: '0 5px',
      },
    },
    loadingContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorText: {
      color: theme.palette.error.main,
      width: 'fit-content',
      margin: '0 auto 5px auto',
    },
    linkContainer: {
      display: 'flex',
      width: 'fit-content',
      margin: '0 auto 20px auto',
    },
    link: {
      color: theme.palette.secondary.main,
      textDecorationColor: theme.palette.secondary.main,
      marginLeft: 5,
      fontWeight: 700,
    },
  })
);
