import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    calendarContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    dateContainer: {
      marginBottom: 10,
    },
    itemsContainer: {
      marginBottom: 25,
    },
    dateText: {
      fontWeight: 700,
      color: theme.palette.secondary.main,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 12,
      },
    },
    loaderContainer: {
      padding: '25px 0',
    },
    centerContent: {
      margin: 'auto',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
    },
    primaryButton: {
      backgroundColor: theme.palette.primary.main,
      border: `2px ${theme.palette.primary.main} solid`,
      padding: '15px 25px',
      borderRadius: 40,
      marginBottom: 20,
      transition: 'border .4s ease-out, background .4s ease-out',
      cursor: 'pointer',
      '& p': {
        color: theme.palette.common.white,
        fontWeight: 700,
        transition: 'color .4s ease-out',
      },
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        '& p': {
          color: theme.palette.primary.main,
        },
      },
    },
    secondaryButton: {
      backgroundColor: 'transparent',
      border: 'none',
      textDecoration: 'underline',
      cursor: 'pointer',
      textDecorationColor: theme.palette.grey[400],
    },
    errorText: {
      color: theme.palette.error.main,
    },
    linkContainer: {
      display: 'flex',
      width: 'fit-content',
      margin: '0 auto 20px auto',
    },
    link: {
      color: theme.palette.secondary.main,
      textDecorationColor: theme.palette.secondary.main,
      marginLeft: 5,
      fontWeight: 700,
    },
  })
);
