import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { FormPage } from './pages';
import { ThemeConfig } from './theme';
import { FormProvider, UsageProvider } from './contexts';

const App = () => {
  const apolloClient = new ApolloClient({
    uri: `${process.env.REACT_APP_APOLLO_CLIENT_URI}`,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={apolloClient}>
      <UsageProvider>
        <FormProvider>
          <ThemeConfig>
            <FormPage />
          </ThemeConfig>
        </FormProvider>
      </UsageProvider>
    </ApolloProvider>
  );
};
export default App;
