import { MeetingTypes } from '../contexts';

export const enumToString = (enumValue: MeetingTypes): string => {
  switch (enumValue) {
    case MeetingTypes.PHYSICAL:
      return 'Fysiskt möte (30 minuter)';
    case MeetingTypes.VIDEO:
      return 'Videomöte (20 minuter)';
  }
};
