import emailjs from '@emailjs/browser';

import { enumToString } from './enumToString';
import { MeetingTypes } from '../contexts';

export enum EmailType {
  INTERNAL = 'INTERNAL',
  CUSTOMER = 'CUSTOMER',
}

type ISendEmail = {
  emailType: EmailType;
  adress?: string;
  city?: string;
  email: string;
  endTime?: string;
  firstName: string;
  lastName: string;
  meetingEmployeeId?: string;
  meetingType: string;
  phone?: string;
  postalCode?: string;
  sqmSize?: string;
  startDate: string;
  startTime: string;
};

export const SendEmail = async (params: ISendEmail) => {
  switch (params.emailType) {
    case EmailType.CUSTOMER:
      try {
        const response = await emailjs.send(
          `${process.env.REACT_APP_EMAIL_SERVICE_ID}`,
          `${process.env.REACT_APP_EMAIL_CUSTOMER_TEMPLATE_ID}`,
          {
            name: `${params.firstName} ${params.lastName}`,
            startTime: params.startTime,
            date: params.startDate,
            meetingType: (() => {
              switch (params.meetingType) {
                case MeetingTypes.VIDEO:
                  return enumToString(MeetingTypes.VIDEO);
                case MeetingTypes.PHYSICAL:
                  return enumToString(MeetingTypes.PHYSICAL);
              }
            })(),
            emailTo: params.email,
          },
          `${process.env.REACT_APP_EMAIL_PUBLIC_API_KEY}`
        );
        console.log('email sent', response);
      } catch (error) {
        console.error('error sending email: ', error);
      }
      break;
    case EmailType.INTERNAL:
      try {
        const response = await emailjs.send(
          `${process.env.REACT_APP_EMAIL_SERVICE_ID}`,
          `${process.env.REACT_APP_EMAIL_INTERNAL_TEMPLATE_ID}`,
          {
            address: params.adress,
            city: params.city,
            email: params.email,
            endTime: params.endTime,
            firstName: params.firstName,
            lastName: params.lastName,
            meetingEmployeeId: params.meetingEmployeeId,
            meetingType: params.meetingType,
            phone: params.phone,
            postalCode: params.postalCode,
            sqmSize: params.sqmSize,
            startDate: params.startDate,
            startTime: params.startTime,
          },
          `${process.env.REACT_APP_EMAIL_PUBLIC_API_KEY}`
        );
        console.log('email sent internal', response);
      } catch (error) {
        console.log('error sending email: ', error);
      }
      break;
  }
};
