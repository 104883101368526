import { gql } from '@apollo/client';

export const GET_AVAILABLE_TIME_GAPS = gql`
  query getAvailableTimeGaps(
    $gapLengthHours: Float!
    $startDateUnix: Int!
    $endDateUnix: Int!
    $meetingType: MeetingType!
    $postalCode: String!
    $employeeIdsConfig: EmployeeIdsConfig!
  ) {
    getAvailableTimeGaps(
      gapLengthHours: $gapLengthHours
      startDateUnix: $startDateUnix
      endDateUnix: $endDateUnix
      meetingType: $meetingType
      postalCode: $postalCode
      employeeIdsConfig: $employeeIdsConfig
    ) {
      startTime
      endTime
      employeeId
      startDate
      hours
    }
  }
`;
