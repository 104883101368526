import React from 'react';
import { Paper, Typography } from '@mui/material';

import useStyles from './styles';

interface IFormSection {
  title: string;
  children: React.ReactNode;
}

export const FormSection = ({ children, title }: IFormSection) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant="h2" className={classes.header}>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};
