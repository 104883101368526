import { createContext } from 'react';

import { IFormState, initialFormState } from './state';
import { IHomeCleaningForm, ValueOf } from './types';

export interface IFormContext extends IFormState {
  validateFields: (step: number) => boolean;
  setFormValues: (
    key: Partial<keyof IHomeCleaningForm>,
    value: Partial<ValueOf<IHomeCleaningForm>>
  ) => void;
  submitForm: () => void;
  sendConfirmationEmails: () => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.');
};

const initialFormContext: IFormContext = {
  ...initialFormState,
  submitForm: stub,
  setFormValues: stub,
  validateFields: stub,
  sendConfirmationEmails: stub,
};

export const FormContext = createContext(initialFormContext);
