import React from 'react';
import { Grid } from '@mui/material';

import stringData from '../../../assets/data/formStringsData.json';
import { meetingTypes } from '../../../assets';
import { useFormContext } from '../../../hooks';
import { Calendar } from '../../Calendar';
import { FormSection } from '../../FormSection';
import { Checkbox } from '../../Checkbox';
import { enumToString } from '../../../helpers';

interface IMeetingInformation {
  handleScrollToButton: () => void;
}
export const MeetingInformation = ({
  handleScrollToButton,
}: IMeetingInformation) => {
  const { homeCleaningFormValues, setFormValues } = useFormContext();
  const meetingInformationString = stringData.steps.meetingInformation;

  return (
    <>
      <FormSection title={meetingInformationString.meetingType.title}>
        <Grid container spacing={1}>
          {meetingTypes.map((meetingType, index) => (
            <Grid item key={index} xs={12} sm={12} md={6}>
              <Checkbox
                isChecked={
                  meetingType.type === homeCleaningFormValues.meetingType
                }
                onClick={() => setFormValues('meetingType', meetingType.type)}
                text={enumToString(meetingType.type)}
              />
            </Grid>
          ))}
        </Grid>
      </FormSection>

      <FormSection title={meetingInformationString.calendar.title}>
        {meetingTypes.map(
          (meetingType, index) =>
            meetingType.type === homeCleaningFormValues.meetingType && (
              <Calendar
                key={index}
                gapLengthHours={meetingType.gapLengthHours}
                meetingType={meetingType.type}
                apiErrorText={meetingInformationString.calendar.apiErrorText}
                apiErrorTextUppsala={
                  meetingInformationString.calendar.apiErrorTextUppsala
                }
                noSelectedDateErrorText={
                  meetingInformationString.calendar.noSelectedDateErrorText
                }
                forwardPaginationText={
                  meetingInformationString.calendar.forwardPaginationText
                }
                backwardsPaginationText={
                  meetingInformationString.calendar.backwardsPaginationText
                }
                handleScrollToButton={handleScrollToButton}
                bookThroughOldForm={
                  meetingInformationString.calendar.bookThroughOldForm
                }
                oldFormUrl={meetingInformationString.calendar.oldFormUrl}
                here={meetingInformationString.calendar.here}
              />
            )
        )}
      </FormSection>
    </>
  );
};
