import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 40,
      padding: '19px 33px',
      border: `solid 2px ${theme.palette.primary.main}`,
      cursor: 'pointer',
      boxShadow: '0 2px 17px rgb(0 0 0 / 16%)',
      animation: '$twitching 4s infinite',
      '& p': {
        color: `${theme.palette.common.white}`,
      },
    },
    text: {
      display: 'flex',
      fontWeight: 700,
      fontSize: 20,
    },
    '@keyframes twitching': {
      '0%': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '25%': {
        backgroundColor: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        scale: 1.05,
      },
      '100%': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  })
);
