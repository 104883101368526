import React from 'react';
import { Grid } from '@mui/material';

import { FormSection } from '../../FormSection';
import { Input } from '../../Input';
import { useFormContext } from '../../../hooks';
import stringData from '../../../assets/data/formStringsData.json';

export const ContactInformation = () => {
  const contactInformationStrings = stringData.steps.contactInformation;

  const { setFormValues, fieldErrors, homeCleaningFormValues } =
    useFormContext();

  return (
    <FormSection title={contactInformationStrings.contactInformation.title}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            placeholder={
              contactInformationStrings.contactInformation.fields.firstName
                .placeholder
            }
            value={homeCleaningFormValues['firstName']}
            errorHelperText={
              contactInformationStrings.contactInformation.fields.firstName
                .errorHelperText
            }
            error={fieldErrors.firstName}
            onChange={(value) => setFormValues('firstName', value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            placeholder={
              contactInformationStrings.contactInformation.fields.lastName
                .placeholder
            }
            errorHelperText={
              contactInformationStrings.contactInformation.fields.lastName
                .errorHelperText
            }
            value={homeCleaningFormValues['lastName']}
            error={fieldErrors.lastName}
            onChange={(value) => setFormValues('lastName', value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            placeholder={
              contactInformationStrings.contactInformation.fields.phone
                .placeholder
            }
            errorHelperText={
              contactInformationStrings.contactInformation.fields.phone
                .errorHelperText
            }
            type="number"
            value={homeCleaningFormValues['phone']}
            error={fieldErrors.phone}
            onChange={(value) => setFormValues('phone', value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Input
            placeholder={
              contactInformationStrings.contactInformation.fields.email
                .placeholder
            }
            errorHelperText={
              contactInformationStrings.contactInformation.fields.email
                .errorHelperText
            }
            error={fieldErrors.email}
            value={homeCleaningFormValues['email']}
            onChange={(value) => setFormValues('email', value)}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
