import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 40,
      padding: '19px 33px',
      border: `solid 2px ${theme.palette.secondary.main}`,
      cursor: 'pointer',
      transition: 'background-color .4s ease-out',
      '& p': {
        color: `${theme.palette.common.white}`,
        transition: 'color .4s ease-out',
      },
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        '& p': {
          color: theme.palette.secondary.main,
        },
        '&:hover p::after': {
          color: theme.palette.secondary.main,
        },
      },
    },
    text: {
      display: 'flex',
      fontWeight: 700,
      fontSize: 20,
      // Arrow right icon
      '&::after': {
        content: '"c"',
        fontSize: 19,
        marginLeft: 50,
        fontFamily: 'alberts',
        color: theme.palette.common.white,
        transition: 'color .4s ease-out',
      },
    },
  })
);
