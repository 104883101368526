import _ from 'lodash';

export const validatePostalCodeRange = (postalCode: string): boolean => {
  const formatPostalCode = Number(postalCode.replace(/ /g, ''));

  const postalCodeRangeOfGothenburg = [
    ..._.range(40009, 43230),
    ..._.range(43300, 43965),
    ..._.range(43966, 44971),
    44940,
    44942,
    44943,
    44944,
    44990,
  ];
  const postalCodeRangeOfStockholm = _.inRange(formatPostalCode, 10003, 19588);

  const postalCodeRangeOfUppsala = [
    ..._.range(74010, 74021),
    ..._.range(74330, 74395),
    74496,
    74497,
    74793,
    74794,
    ..._.range(75217, 75760),
  ];

  return (
    postalCodeRangeOfGothenburg.includes(formatPostalCode) ||
    postalCodeRangeOfStockholm ||
    postalCodeRangeOfUppsala.includes(formatPostalCode)
  );
};
