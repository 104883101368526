import { format } from 'date-fns';
import { sv } from 'date-fns/locale';

const getYear = (date: string) => {
  return date.slice(0, 4);
};
const getMonth = (date: string) => {
  return date.slice(4, 6);
};
const getDate = (date: string) => {
  return date.slice(6);
};

const stringDateToDateObject = (date: string) => {
  const dateObject = new Date();
  dateObject.setDate(Number(getDate(date)));
  dateObject.setMonth(Number(getMonth(date)) - 1);
  dateObject.setFullYear(Number(getYear(date)));
  return dateObject;
};

export const formatUserInputDate = (inputDate: number) => {
  const stringDate = JSON.stringify(inputDate);
  const year = getYear(stringDate);
  const month = getMonth(stringDate);
  const date = getDate(stringDate);

  return `${year}-${month}-${date}`;
};

export const formatDisplayDate = (stringDate: string) => {
  // Example input: '20221210'
  // Example output: 'LÖRDAG 10 DECEMBER'
  const date = stringDateToDateObject(stringDate);
  const formattedDate = format(date.getTime(), 'EEEE d MMMM', { locale: sv });
  return formattedDate.toUpperCase().replace(/\./g, '');
};

export const calculateMonthlyApiTimeIntervalUnix = (pagination: number) => {
  const oneDayInMilliSec = 24 * 60 * 60 * 1000;
  const twentySevenDaysInMilliSec = oneDayInMilliSec * 27; // four weeks minus one day

  let startDateMilli = new Date(Date.now() + oneDayInMilliSec).getTime(); // tomorrow
  const weeksAheadMilli = twentySevenDaysInMilliSec * pagination;
  const daysAheadMilli = oneDayInMilliSec * pagination;

  startDateMilli += weeksAheadMilli + daysAheadMilli;

  const endDateMilli = startDateMilli + twentySevenDaysInMilliSec;

  const startDateUnix = Math.floor(startDateMilli / 1000);
  const endDateUnix = Math.floor(endDateMilli / 1000);

  return { endDateUnix, startDateUnix }; // an interval of 28 days (four weeks)
};
