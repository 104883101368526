import formString from '../../assets/data/formStringsData.json';

export enum GACategory {
  STANDARD_FORM = 'STANDARD_FORM',
  HOME_FORM = 'HOME_FORM',
}
export enum GAAction {
  FORM_START = 'FORM_START',
  FORM_PAGE_1 = 'FORM_PAGE_1',
  FORM_PAGE_2 = 'FORM_PAGE_2',
  FORM_PAGE_3 = 'FORM_PAGE_3',
  BOOKING_SUCCESS = 'BOOKING_SUCCESS',
}

export const userStartedForm = (category: string) => {
  // This function will fire immediately.
  // Wait 3 seconds until GTM loads in parent window (bettysstad.se)
  setTimeout(() => {
    if (window.parent) {
      window.parent.postMessage(
        {
          task: 'send_user_started_form',
          action: GAAction.FORM_START,
          category: category,
        },
        formString.form.parentUrl // switch to local development url in development
      );
    }
  }, 3000);
};
export const userVisitedPage = (category: string, action: string) => {
  // This function will sometimes fire immediately.
  // Wait 3 seconds until GTM loads in parent window (bettysstad.se)
  setTimeout(() => {
    if (window.parent) {
      window.parent.postMessage(
        {
          task: 'send_user_visited_page',
          category: category,
          action: action,
        },
        formString.form.parentUrl // switch to local development url in development
      );
    }
  }, 3000);
};

export const userSubmittedForm = (category: string) => {
  if (window.parent) {
    window.parent.postMessage(
      {
        task: 'send_user_submitted_form',

        category: category,
        action: `${GAAction.BOOKING_SUCCESS}/HOME`,
      },
      formString.form.parentUrl // switch to local development url in development
    );
  }
};
